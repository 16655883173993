<template>
    <InputContainer
        :name="name"
        :input-id="id"
        :label="label"
        :help-text="helpText"
        :errors="errors"
        :class="containerClass"
        label-class="input-label"
    >
        <textarea
            :id="id"
            :value="value === null ? '' : value"
            :aria-invalid="isErrored ? 'true' : 'false'"
            :aria-errormessage="isErrored ? 'input-error-' + name : ''"
            :name="name"
            :disabled="submitting"
            v-bind="$attrs"
            :class="[{ 'input-is-errored': isErrored }, inputClass]"
            placeholder=" "
            @input="value = $event.target?.value"
        ></textarea>
    </InputContainer>
</template>

<script setup lang="ts">
import InputContainer from './components/InputContainer.vue';
import {
    defaultEmits,
    DefaultProps,
    defaultPropsDefaults,
} from '~/composables/useInput';

defineOptions({
    inheritAttrs: false,
});

const emit = defineEmits([...defaultEmits]);

const props = withDefaults(defineProps<DefaultProps>(), defaultPropsDefaults);

const { id, value, errors, isErrored } = useInput(props, emit);
</script>
